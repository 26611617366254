<template>
    <div class="grid crud-demo">
        <Dialog v-model:visible="editInvoice" :closable="true" :modal="true"  :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '65vw'}" :maximizable="true">
            <LinkPagoUI :id="editCard.id" @updated="showLinkPago" @deleted="showLinkPago" @selected="showLinkPago" />
        </Dialog>
        <Toast />
        <Loader v-model="loading" />
        <div class="col-12">
            <div class="card">
                <Toast />
                <Panel header="Administrador">
                    <BasicFormToolbar  @refresh="refresh" @list="list" :actions="[ 'list', 'refresh']" />
                    <Fieldset :toggleable="true" legend="Parametros">
                        <div class="p-fluid p-formgrid grid">
                            <div class="field col-4">
                                <label>Fecha Inicial</label>
                                <Calendar dateFormat="dd/mm/yy" v-model="entity.initial_date" :showIcon="true" :class="{ 'p-invalid': validate.validations.initial_date === false }" />
                                <small class="p-invalid" v-if="validate.validations.initial_date === false"> Favor de llenar el campo </small>
                            </div>
                            <div class="field col-4">
                                <label>Fecha Final</label>
                                <Calendar dateFormat="dd/mm/yy" v-model="entity.final_date" :showIcon="true" :class="{ 'p-invalid': validate.validations.final_date === false }" />
                                <small class="p-invalid" v-if="validate.validations.final_date === false"> Favor de llenar el campo </small>
                            </div>
                        </div>
                    </Fieldset>
                    <br />
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-12">
                            <Fieldset :toggleable="true" legend="Resultados">
                                <BasicDatatable 
                                :menuModel="[
                                    {label: 'Copiar Link', icon: 'pi pi-fw pi-copy', action: 'copy'},
                                    {label: 'Mandar Link por WhatsApp', icon: 'pi pi-fw pi-whatsapp', action: 'whatsapp'},
                                    {label: 'Enviar por e-mail', icon: 'pi pi-fw pi-envelope', action: 'send'},
                                ]"
                                @copy="copy" @whatsapp="whatsapp" @send="send"
                                @view="showLinkPago" :contextMenu="true" :rowaction="true" :rowview="true" :headers="headers" :rows="entities" />
                            </Fieldset>
                        </div>
                    </div>
                </Panel>
            </div>
        </div>
    </div>
</template>
<script>
import { BWInvoice } from '../../../models/custom/business_wallet/BWInvoice';
import Session from '../../../mixins/sessionMixin';
import { Rule, ErrorToast, validate, fillObject, HeaderGrid, Toast } from '../../../utilities/General';
import Loader from '../../../components/general/Loader.vue';
import LinkPagoUI from './LinkPago.vue';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue';
import BasicDatatable from "../../../components/general/BasicDatatable.vue";

export default {
    mixins: [Session],
    data() {
        return {
            editInvoice: false,
            editCard: new BWInvoice(this.session),
            entity: {
                id_customer: 0,
                initial_date: new Date(),
                final_date: new Date(),
            },
            entities: [],
            tags: [],
            customers: [],
            filters: null,
            loading: false,
            loadingInputs: false,
            headers: [ 
                new HeaderGrid('ID','id'),
                new HeaderGrid('Cliente','customerInformation_name'),
                new HeaderGrid('Descripcion','invoiceInformation_description'),
                new HeaderGrid('Total','orderInformation_amountDetails_totalAmount', {type: 'currency', function: 'SUM'}),
                new HeaderGrid('Vencimiento','invoiceInformation_dueDate', { type: 'date'}),
                new HeaderGrid('Dias Pendientes', 'dias_pendientes', { formula: 'today_difference', expression: 'invoiceInformation_dueDate'}),
                new HeaderGrid('Saldo Pendiente', 'sal_pendiente',{type: 'currency', function: 'SUM'}),
                  new HeaderGrid('Saldo Pagado', 'auth_amount',{type: 'currency', function: 'SUM'}),
                 new HeaderGrid('Estatus', 'status')
            ],
            rules: [new Rule({ name: 'initial_date' }), new Rule({ name: 'final_date' })],
            validate: {
                valid: false,
                validations: {
                    initial_date: null,
                    final_date: null,
                },
            },
        };
    },
    components: { Loader, LinkPagoUI,BasicFormToolbar, BasicDatatable },
    created() {
        this.initFilters();
    },

    async mounted() {
        await this.refresh();
    },
    methods: {
        copy(payload) {
            if (payload.paymentLink) {
                navigator.clipboard.writeText(payload.paymentLink);
                this.$toast.add(
                    new Toast({
                        summary: "Link de Pago",
                        detail: "Se ha copiado el link en el portapapeles",
                        })
                );
            }else {
                this.$toast.add(new ErrorToast("No se ha generado el URL"));
            }
        },
        whatsapp(payload) {
            if (payload.paymentLink) {
                window.open("https://api.whatsapp.com/send?text=" + encodeURIComponent(payload.paymentLink));
            }else {
                this.$toast.add(new ErrorToast("No se ha generado el URL"));
            }
        },
        async send(payload) {
            this.loading = true;
            try {
                let invoice = new BWInvoice(this.session);
                invoice = fillObject(invoice, payload);
                let response = await invoice.send();
                let index = this.entities.findIndex(x => x.id == response.id);
                this.entities[index].paymentLink = response.paymentLink;
                this.$toast.add(
                    new Toast({
                        summary: "Link de Pago",
                        detail: "Se ha enviado correctamente el correo",
                    })
                );
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        edit(entity) {
            this.entity = fillObject(this.entity, entity);
        },
        calcular(FechaVencimiento) {
            var fechaini = new Date();

            var fechafin = new Date(FechaVencimiento);

            var diasdif = fechafin.getTime() - fechaini.getTime();

            var contdias = Math.round(diasdif / (1000 * 60 * 60 * 24));

            if (contdias < 0) {
                return 'Link Vencido';
            } else {if (contdias == 0) {
                return 'Este Link es valido solo por hoy';
            } else if (contdias == 1) {
                return 'Este Link vencera en ' + contdias + ' dia';
            }
            else {
                   return 'Este Link vencera en ' + contdias + ' dias';
            }
            }
        },
        formatDate(value) {
            return new Date(value).toLocaleDateString('es-ES');
        },

        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        showLinkPago(data) {
            this.editCard.id = data.id;
            this.editInvoice = true;
        },
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                id: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
                },
                invoiceInformation_descrption: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
                },
                paymentLink: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
                },
                invoiceInformation_dueDate: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
                },
                Estatus: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
                },
            };
        },
        async list() {
            this.loading = true;
            this.editInvoice = false;
            try {
                //validacion de campos
                this.validate = validate(this.entity, this.rules);
                if (!this.validate.valid) throw 'Favor de validar los campos';
                var date2 = this.entity.final_date;

                const formatDate2 = (date2) => {
                    let formatted_date2 = date2.getFullYear() + '-' + (date2.getMonth() + 1) + '-' + date2.getDate() + ' ' + '23:59:59';
                    return formatted_date2;
                };

                this.entities = await new BWInvoice().data({
                    customer: 0,
                    id_branch: this.session.branch,
                    id_company: this.session.company,
                    initial_date: formatDate2(this.entity.initial_date),
                    final_date: formatDate2(date2),
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async refresh() {
            this.loading = true;
            try {
                (this.entity = {
                    id_customer: 0,
                    initial_date: new Date(),
                    final_date: new Date(),
                }),
                    await this.list();
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
<style scoped lang="scss">
.table-header {
    display: flex;
    justify-content: space-between;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;

        .p-button {
            margin-bottom: 0.25rem;
        }
    }
    .p-chip.custom-chip {
        background: var(--primary-color);
        color: var(--primary-color-text);
    }
    .p-chip.tag-chip {
        background: #2c9663;
        color: var(--primary-color-text);
    }
}
</style>